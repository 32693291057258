<template>
  <div class="administrator">
    <Loading v-if="isLoading" />
    <div v-if="!isLoading">
    <div  class="content administrator__content">
      <h2 class="content__title">New moderator</h2>
      <moderator-form  ref='moderatorForm' @enableButton="enableButton"/>
      <expertise-selection ref='expertiseSelection' @expertiseSelected="expertiseSelected"/>
    </div>
    <div class="administrator__footer">
      <button
        id="next"
        class="footer__button"
        :disabled="(!isEnable || !isExpertiseSelected)"
        @click="createProfile()">
        Create moderator
      </button>
    </div>
    </div>
  </div>
</template>

<script>
import ExpertiseSelection from './Components/ExpertiseSelection.vue';
import ModeratorForm from './Components/ModeratorForm.vue';
import Loading from "../../components/Loading.vue";

export default {
  name: 'Administrator',
  components: {
    ModeratorForm,
    ExpertiseSelection,
    Loading,
  },

  data() {
    return {
      data: null,
      isEnable: false,
      isExpertiseSelected: false,
      submitData: {},
      personalData: {},
      expertiseSelection: [],
      evaluator: {},
      isLoading: false,
    };
  },

  methods: {

    async createProfile() {
      this.isLoading = true;
      this.personalData = this.$refs.moderatorForm.submit();
      this.expertiseSelection = await this.$refs.expertiseSelection.submit();
      this.submitData = {
        email: this.personalData.email,
        user: this.personalData.user,
        profession_id: this.expertiseSelection.profession_id,
        specialization_id: this.expertiseSelection.specialization_id,
        area_ids: this.expertiseSelection.area_ids,
      };
      this.evaluator = JSON.stringify(this.submitData);
      const data = JSON.parse(this.evaluator);
      const response = await this.$store.dispatch(
        'evaluator/registerEvaluator', data,
      );
      if (response.status !== 200 && response.status !== 204) {
        this.$toast.add({
          severity: 'error',
          summary: response,
          detail: 'Error creating user',
          life: 4000,
        });
      } else {
        this.$toast.add({
          severity: 'success',
          summary: 'Success Registry',
          detail: 'An evaluator was created correctly',
          life: 4000,
        });
        setTimeout(() => { this.$router.go(); }, 4000);
      }
      this.isLoading = false;
    },

    enableButton(validForm) {
      this.isEnable = validForm;
    },
    expertiseSelected(expertiseSelected) {
      this.isExpertiseSelected = expertiseSelected;
    },
  },
};
</script>
<style lang="scss" scoped>
.administrator {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
  }
}

.content {
  &__title {
    font-size: 2rem;
    margin: 1rem 2rem 1rem;
  }
}

.footer {
  &__button {
    @include primary-button();
    margin-top: 3rem;
  }
}
</style>
