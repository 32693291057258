<template>
    <div class="expertise-selection">
        <h4 class="expertise-selection__info">
            Please assign fiel of knowledge whitch will be evaluated for this moderator
        </h4>
        <div class="card expertise-selection__card">
            <div class="row card__row">
                <label class="row__label"><b>Expertise*</b></label>
                <Dropdown class="row__select" v-model="expertiseSelected"
                :options="expertises"
                @change="expertiseChanged"
                optionLabel="name"
                placeholder="Select expertise"/>
            </div>
            <div class="row card__row">
            <label for="specialization" class="row__label"><b>Specialization</b></label>
            <Dropdown class="row__select" v-model="specializationSelected"
              :options="specializations"
              @change="specializationChanged"
              optionLabel="name"
              placeholder="Select specialization"/>
          </div>
          <div class="row card__row">
            <label for="specialization" class="row__label"><b>Areas</b></label>
            <MultiSelect class="row__select" v-model="areasSelected"
              :options="areas" optionLabel="name"
              placeholder="Select areas"/>
          </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'ExpertiseSelection',
  data() {
    return {
      expertiseSelected: '',
      specializationSelected: '',
      areasSelected: [],

      expertises: [],
      specializations: [],
      areas: [],

      isEnable: false,
    };
  },

  async mounted() {
    this.getExpertise();
  },

  methods: {
    submit() {
      const areas = [];
      for (const area of this.areasSelected) {
        areas.push(area.id);
      }
      return {
        profession_id: this.expertiseSelected.id,
        specialization_id: this.specializationSelected.id,
        area_ids: areas,
      };
    },
    async getExpertise() {
      const response = await this.$store.dispatch('freelancer/getProfessions');
      if (!response.length) {
        this.$toast.add({
          severity: 'error',
          summary: response,
          detail: 'Failed to get professions',
          life: 4000,
        });
      } else {
        this.expertises = response;
      }
    },

    async getSpecializations() {
      this.specializations = await this.$store
        .dispatch('freelancer/getSpecializations', this.expertiseSelected.id);
    },

    async getAreas() {
      this.areas = [];
      this.areas = await
      this.$store.dispatch('freelancer/getAreas', this.specializationSelected.id);
    },
    expertiseChanged() {
      this.isEnable = true;
      this.$emit('expertiseSelected', this.isEnable);

      this.getSpecializations();
    },
    specializationChanged() {
      this.areasSelected = [];
      this.getAreas();
    },
  },
};
</script>

<style lang="scss" scoped>
.expertise-selection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__info {
    margin-left: 2rem;
  }
  &__card {
    border: 1px none #ccc;
    border-radius: $border-radius-md;
    box-shadow: $box-shadow;
    padding: 2rem 2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 65%;
    margin: auto;
  }
}
.card {
  &__row {
    display: flex;
    margin: .5rem 2rem;
  }
}
.row {
  &__label {
    margin: 0 1rem 0 .5rem;
  }
  &__select {
    border-radius: 25px;
    width: 100%;
  }
}
</style>
