<template>
  <main class="moderator-form">
      <form class="form" @submit.prevent="submitForm">
        <div class="row form__row">
          <div class="column row__column">
            <label for="firstName" class="column__input-label">First name *</label>
            <input autocomplete="off" placeholder="Enter name"
              class="column__input"
              type="text"
              id="firstName"
              v-model.trim="firstName"
              required
            />
            <div class="error-message">
              <span class="form__error" v-if="msg.firstName">{{msg.firstName}}</span>
            </div>
          </div>
          <div class="column row__column">
            <label class="column__input-label" for="secondName">Second name</label>
            <input autocomplete="off" placeholder="Enter name"
              class="column__input"
              type="text"
              id="secondName"
              v-model.trim="secondName"
            />
            <div class="error-message">
              <span class="form__error" v-if="msg.secondName">{{msg.secondName}}</span>
            </div>
          </div>
        </div>
        <div class="row form__row">
          <div class="column row__column">
            <label for="lastName" class="column__input-label">Last name *</label>
            <input autocomplete="off" placeholder="Enter last name"
              class="column__input"
              type="text"
              id="lastName"
              v-model.trim="lastName"
              required
            />
            <div class="error-message">
              <span class="form__error" v-if="msg.lastName">{{msg.lastName}}</span>
            </div>
          </div>
          <div class="column row__column">
            <label class="column__input-label" for="citizenship"
              >Citizenship *</label>
            <Dropdown class="column__select" v-model="selectedCitizenship"
              :options="countriesList"
              placeholder="Select country"/>
          </div>
        </div>
        <div class="row form__line">
            <label for="email" class="column__input-label">Email * <br></label>
            <input autocomplete="off" placeholder="Enter email"
                class="column__input"
                type="text"
                id="email"
                v-model.trim="email"
                required
            />
            <div class="error-message">
                <span class="form__error" v-if="msg.email">{{msg.email}}</span>
            </div>
        </div>
        <div class="row form__row">
          <div class="column row__column">
            <label class="column__input-label" for="password">Password *</label>
            <input autocomplete="off" placeholder="Enter password"
              class="column__input"
              type="password"
              id="password"
              v-model="password"
              required
            />
            <div class="error-message__pdw">
              <span class="form__error" v-if="msg.password">{{msg.password}}</span>
            </div>
          </div>
          <div class="column row__column">
            <label class="column__input-label" for="confirmPassword"
              >Confirm Password *</label>
            <input autocomplete="off" placeholder="Repeat the password"
              class="column__input"
              type="password"
              id="confirmPassword"
              v-model="confirmPassword"
              required
            />
            <div class="error-message__pdw">
              <span class="form__error" v-if="msg.confirmPassword">{{msg.confirmPassword}}</span>
            </div>
          </div>
        </div>
      </form>
  </main>
</template>

<script>

export default {
  name: 'ModeratorForm',
  data() {
    return {

      firstName: '',
      secondName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      msg: [],
      selectedCitizenship: null,
      photo: 'https://brains.guane.dev/img/userIcon.d828cb89.png',
      countriesList: ['Colombia', 'Argentina', 'USA', 'Mexico'],
      formCorrect: false,
      submitForm: '',
    };
  },
  watch: {
    firstName(value) {
      // binding this to the data value in the email input
      const exp = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
      this.validateName(value, 'firstName', exp);
    },
    secondName(value) {
      const exp = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]*$/;
      this.validateName(value, 'secondName', exp);
    },
    lastName(value) {
      const exp = /^[a-zA-Z\s*À-ÿ\u00f1\u00d1]+$/;
      this.validateName(value, 'lastName', exp);
    },
    email(value) {
      this.validateEmail(value);
    },
    password(value) {
      this.validatePassword(value);
    },
    confirmPassword(value) {
      this.verifyPassword(value);
    },
  },
  methods: {
    submit() {
      return {
        email: this.email,
        user: {
          first_name: this.firstName,
          second_name: this.secondName,
          last_name: this.lastName,
          citizenship: this.selectedCitizenship,
          photo: this.photo,
          password: this.password,
          confirm_password: this.confirmPassword,
        },
      };
    },
    validateName(value, inputId, exp) {
      if (exp.test(value)) {
        this.msg[inputId] = '';
      } else {
        this.msg[inputId] = 'Invalid name';
      }
      this.checkForm();
    },
    validateEmail(value) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg.email = '';
      } else {
        this.msg.email = 'Invalid Email Address';
      }
      this.checkForm();
    },
    validatePassword(value) {
      if (/^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/.test(value)) {
        this.msg.password = '';
      } else {
        this.msg.password = ('Password must contain at least: '
        + 'one capital letter, one number and one special character');
      }
      if (this.password.length < 8) {
        this.msg.password = 'Password must be at least 8 characters';
      }
      this.checkForm();
    },
    verifyPassword(value) {
      if (value !== this.password) {
        this.msg.confirmPassword = 'Passwords do not match';
      } else {
        this.msg.confirmPassword = '';
      }
      this.checkForm();
    },
    checkForm() {
      if (!(Object.values(this.msg).some((error) => error.length))
      && this.firstName !== '' && this.lastName !== '' && this.confirmPassword !== ''
      && this.selectedCitizenship !== null && this.email !== '') {
        this.formCorrect = true;
      } else {
        this.formCorrect = false;
      }
      this.$emit('enableButton', this.formCorrect);
    },
  },
};
</script>
<style lang="scss" scoped>
.moderator-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form {
  &__error {
    color: $alert-color;
    font-size: 12px;
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
.row {
  &__column {
    width: 48%;
  }
}
.column {
  &__input {
    @include input(100%);
  }
  &__input-label {
    margin: 0 0 0 0.5rem;
  }
  &__select {
    border-radius: 25px;
    width: 100%;
    margin-bottom: 17px;
  }
  &__error {
    width: 280px;
  }
}
.error-message {
  overflow: hidden;
  width: 100%;
  height: 17px;
  margin-left: 0.5rem;
  &__pdw {
    width: 100%;
    height: 60px;
    max-width: 280px;
    margin-left: 0.5rem;
  }
}
</style>
